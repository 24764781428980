<template>
  <div class="container d-flex justify-content-center">
    <form action="" class="form w-100">
      <ControlledInputField
        inputFor="name"
        inputName="Name"
        :errors="errors.name"
      >
        <input
          type="text"
          placeholder="Name"
          name="name"
          v-model="formData.name"
        />
      </ControlledInputField>

      <ControlledInputField
        inputFor="email"
        inputName="Email"
        :errors="errors.email"
      >
        <input
          type="email"
          placeholder="Email"
          name="email"
          v-model="formData.email"
        />
      </ControlledInputField>

      <ControlledInputField
        inputFor="password"
        inputName="Password"
        :errors="errors.password"
      >
        <input
          autocomplete="false"
          type="password"
          placeholder="Password"
          name="password"
          v-model="formData.password"
        />
      </ControlledInputField>

      <ControlledInputField
        inputFor="password"
        inputName="Confirm Password"
        :errors="errors.passwordConfirmation"
      >
        <input
          autocomplete="false"
          type="password"
          placeholder="Password Confirmation"
          name="password-confirmation"
          v-model="formData.passwordConfirmation"
        />
      </ControlledInputField>

      <CallToActionButton
        :disabled="isLoading"
        class="mt-4"
        @buttonClick="handleSubmit"
      >
        Create Admin
      </CallToActionButton>
    </form>
  </div>
</template>

<script>
import { createAdmin } from "@/api/admins/adminsApi.js";
import { Validator } from "@/validation/validator.js";
import { mapGetters } from "vuex";

export default {
  name: "CreateAdmin",

  data() {
    return {
      formData: {
        email: "",
        name: "",
        password: "",
        passwordConfirmation: "",
      },
      errors: {
        email: null,
        name: null,
        password: null,
        passwordConfirmation: null,
      },
    };
  },

  methods: {
    handleSubmit(event) {
      event.preventDefault();

      const credentials = {
        name: this.formData.name,
        email: this.formData.email,
        password: this.formData.password,
        password_confirmation: this.formData.passwordConfirmation,
      };

      const { success, errors } = Validator.validate(this.formData, {
        email: "required|email",
        name: "required",
        password: "required|min:8",
        passwordConfirmation: "required|min:8|matches:password",
      });

      this.errors = errors;

      if (success) {
        try {
          createAdmin(credentials);
          this.$router.push('/admins');
        } catch (error) {
          console.log(error);
        }
      }
    },
  },

  computed: {
    ...mapGetters("loading", {
      isLoading: "getIfIsLoading",
    }),
  },
};
</script>

<style scoped>
.form {
  max-width: 500px;
}
</style>
