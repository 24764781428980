<template>
    <ListLayout>
        <template #header>
            <h2 class="mb-0">All Announcements List</h2>
            <router-link class="nav-button" to="/announcements/new">
                <CallToActionButton>Add Announcement</CallToActionButton>
            </router-link>
        </template>
        <template #list>
            <v-card class="w-100" elevation="5">
                <v-table class="mb-0 text-left no-scrollbar" fixed-header
                    :height="announcements.length >= 20 ? '80vh' : null">
                    <template v-slot:default>
                        <thead>
                            <tr>
                                <th>Id</th>
                                <th>Title</th>
                                <th>Description</th>
                                <th>Link</th>
                                <th>Go To</th>
                                <th>Date</th>
                                <th></th>
                                <th></th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="announcement in announcements" :key="announcement?.id">
                                <td>
                                    <router-link class="nav-button" :to="`/announcements/${announcement.id}`">
                                        {{ announcement.id || "-" }}
                                    </router-link>
                                </td>
                                <td>{{ announcement.title || "-" }}</td>
                                <td>{{ announcement.description || "-" }}</td>
                                <td>{{ announcement.link || "-" }}</td>
                                <td>{{ announcement.goto || "-" }}</td>
                                <td>{{ announcement.date || "-" }}</td>
                                <td>
                                    <button @click="confirmDelete(announcement)">
                                        <v-icon color="red">fas fa-xmark</v-icon>
                                    </button>
                                </td>
                                <td>
                                    <router-link class="nav-button" :to="`/announcements/${announcement.id}`">
                                        <v-icon class="view-icon" size="x-small">far fa-eye</v-icon>
                                    </router-link>
                                </td>
                                <td>
                                    <button @click="confirmRePublish(announcement)">
                                        <v-icon color="blue" size="x-small">fas fa-clone</v-icon>
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </template>
                </v-table>
            </v-card>
            <v-dialog v-model="rePublishDialog" class="pages">
                <v-card>
                    <v-card-title class="headline">Confirm Re-Publish Announcement #{{ announcement?.id }}</v-card-title>
                    <v-card-text style="color: black;">Are you sure you want to re-publish the <strong>{{ announcement?.title
                            }}</strong>
                        announcement?</v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="grey" text @click="rePublishDialog = false" style="color: white;">Cancel</v-btn>
                        <v-btn color="blue" text @click="rePublishAnnouncement" style="color: white;">Publish</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <v-dialog v-model="dialog" class="pages">
                <v-card>
                    <v-card-title class="headline">Confirm Deletion Announcement #{{ announcement?.id }}</v-card-title>
                    <v-card-text style="color: black;">Are you sure you want to delete the <strong>{{ announcement?.title
                            }}</strong>
                        announcement?</v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="blue" text @click="dialog = false" style="color: white;">Cancel</v-btn>
                        <v-btn color="red" text @click="deleteAnnouncement" style="color: white;">Delete</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </template>
    </ListLayout>
</template>

<script>
import { getAllAnnouncements, deleteAnnouncement } from "@/api/announcements/announcementsApi.js";
import { addAnnouncement } from "../api/announcements/announcementsApi";

export default {
    name: "AllAnnouncementsList",

    data() {
        return {
            announcements: [],
            announcement: null,
            dialog: false,
            rePublishDialog: false
        };
    },

    async created() {
        this.announcements = await getAllAnnouncements();
    },

    methods: {
        confirmDelete(announcement) {
            this.announcement = announcement;
            this.dialog = true;
        },

        async deleteAnnouncement() {
            try {
                await deleteAnnouncement(this.announcement.id);
                this.dialog = false;
                this.announcement = null;
                this.announcements = await getAllAnnouncements();
            } catch (error) {
                console.log(error);
            }
        },

        confirmRePublish(announcement) {
            this.announcement = announcement;
            this.rePublishDialog = true;
        },

        async rePublishAnnouncement() {
            try {
                await addAnnouncement(
                    {
                        title: this.announcement.title,
                        description: this.announcement.description,
                        link: this.announcement.link,
                        goto: this.announcement.goto,
                        date: this.announcement.date
                    }
                );
                this.rePublishDialog = false;
                this.announcements = await getAllAnnouncements();
            } catch (error) {
                this.errors = error.response.data.errors;
                console.error(error);
            }
        },
    }
};
</script>

<style scoped>
.page-title {
    text-align: left;
}

.title-wrapper {
    justify-content: space-between;
    align-items: center;
}

.nav-button {
    text-decoration: none;
    margin-bottom: 5px;
}

.custom-button {
    margin-top: 0 !important;
}
</style>