<template>
  <ViewLayout v-if="practice">
    <template #item-display>
      <div class="practice-title-wrapper d-flex align-items-center justify-content-between mb-5">
        <h2 class="text-left">Practice: {{ practice.name }}</h2>
        <div class="action-buttons d-flex justify-content-end">
          <div class="text-left">
            <CallToActionButton @buttonClick="
              isPracticeActive = !isPracticeActive;
            activatePopup = !activatePopup;
            " :class="[{ red: isPracticeButtonActive }, { green: !isPracticeButtonActive }]">
              <ConfirmationDialogue cancellationText="Cancel"
                :confirmationText="isPracticeActive ? 'Activate' : 'Deactivate'" @success="handlePracticeState"
                @canceled="resetPracticeState" v-model:popup="activatePopup">
                <template #title>
                  {{ isPracticeActive ? "Activate" : "Deactivate" }}
                  Practice??
                </template>
                <template #subtitle>Are you sure you want to
                  {{ isPracticeActive ? "activate" : "deactivate" }} this
                  practice??</template>
              </ConfirmationDialogue>
              {{ this.isPracticeButtonActive ? "Deactivate" : "Activate" }}
            </CallToActionButton>

            <small class="error-message ml-2">{{
              activateDeactivateError
            }}</small>
          </div>
          <div class="text-left">
            <CallToActionButton>
              <PatientsImport v-model:isOpen="importPatientsPopup" :practiceId="fullPractice.id"
                @error="importPatientsError = 'Something Went wrong, try again'" @success="importPatientsError = null">
              </PatientsImport>
              Import Patients
            </CallToActionButton>
            <small class="error-message ml-2">{{ importPatientsError }}</small>
          </div>
        </div>
      </div>
      <div class="practice-title-wrapper d-flex align-items-center justify-content-between mb-5">
        <h2 class="text-left">CRM: {{ practice.name }}</h2>
        <div class="action-buttons d-flex justify-content-end">
          <div class="text-left">
            <CallToActionButton @buttonClick="
              isCrmActive = !isCrmActive;
            activateCrmPopup = !activateCrmPopup;
            " :class="[{ red: isCrmButtonActive }, { green: !isCrmButtonActive }]">
              <ConfirmationDialogue cancellationText="Cancel"
                :confirmationText="isCrmActive ? 'Activate' : 'Deactivate'" @success="handleCrmState"
                v-model:popup="activateCrmPopup">
                <template #title>
                  {{ isCrmActive ? "Activate" : "Deactivate" }}
                  CRM??
                </template>
                <template #subtitle>Are you sure you want to
                  {{ isCrmActive ? "activate" : "deactivate" }}
                  CRM??</template>
              </ConfirmationDialogue>
              {{ isCrmButtonActive ? "Deactivate" : "Activate" }}
            </CallToActionButton>

            <small class="error-message ml-2">{{
              activateDeactivateError
            }}</small>
          </div>
        </div>
      </div>

      <ItemInfo :item="practice" />
    </template>
    <template #lists>
      <ListLayout>
        <template #header>
          <h2>Users</h2>
        </template>
        <template #list>
          <DataTable v-if="workers.info.length > 0" :items="workers.info" :titles="workers.keys" />
          <TableEmptyState message="There are no Users yet in this practice" v-else />
        </template>
      </ListLayout>

      <ListLayout class="mt-5">
        <template #header>
          <h2>Rooms</h2>
        </template>
        <template #list>
          <DataTable class="w-100" v-if="rooms.info.length > 0" :items="rooms.info" :titles="rooms.keys" />
          <TableEmptyState message="There are no Rooms yet in this practice" v-else />
        </template>
      </ListLayout>
    </template>
  </ViewLayout>
  <PracticeSubscriptionDisplay v-if="fullPractice" :subscription="subscription" :practiceId="fullPractice.id"
    @subscriptionChanged="subscriptionChanged"></PracticeSubscriptionDisplay>
  <PracticeAddons v-if="fullPractice" :practiceId="fullPractice.id" class="mt-5"></PracticeAddons>
  <PracticeSms v-if="fullPractice" :practiceId="fullPractice.id" class="mt-5"></PracticeSms>
</template>

<script>
import {
  getPracticeById,
  deactivatePractice,
  activatePractice,
  deactivateCrm,
  activateCrm
} from "@/api/practices/practicesApi.js";
import PatientsImport from "@/components/PatientsImport";
import PracticeSubscriptionDisplay from "@/components/subscription/PracticeSubscriptionDisplay";

export default {
  name: "SinglePractice",
  components: {
    PatientsImport,
    PracticeSubscriptionDisplay
  },
  data() {
    return {
      fullPractice: null,
      subscription: null,
      practice: null,
      workers: {
        keys: [
          { first_name: "First Name" },
          { last_name: "Last Name" },
          { email: "Email" },
          { is_active: "Is Active" },
          { is_assistant: "Is Assitant" },
          { phone_number: "Phone Number" },
          { last_active: "Last Active" }
        ],
        info: [],
      },
      rooms: {
        keys: [
          { id: "Room Id" },
          { title: "Room Name" },
          { description: "Description" },
        ],
        info: [],
      },
      isPracticeButtonActive: false,
      isPracticeActive: false,
      isCrmButtonActive: false,
      isCrmActive: false,
      activatePopup: false,
      activateCrmPopup: false,
      importPatientsPopup: false,
      activateDeactivateError: null,
      importPatientsError: null,
    };
  },

  async created() {
    this.fullPractice = await getPracticeById(this.$route.params.id);
    this.isCrmActive = this.fullPractice.config?.isCrmActive ? true : false;
    this.isCrmButtonActive = this.isCrmActive
    this.subscription = this.fullPractice.subscription;
    this.isPracticeActive = this.fullPractice.is_active ? true : false;
    this.isPracticeButtonActive = this.isPracticeActive
    this.practice = this.setPracticeInfo();
    this.workers.info = this.fullPractice.workers;
    this.rooms.info = this.fullPractice.rooms;
  },

  methods: {
    setPracticeInfo() {
      return {
        ID: this.fullPractice.id,
        Owner: `${this.fullPractice.owner.first_name} ${this.fullPractice.owner.last_name}`,
        Name: this.fullPractice.name,
        Email: this.fullPractice.email,
        Country: this.fullPractice.country,
        "Phone number": this.fullPractice.phone_number,
        "Default currency": this.getPracticeCurrency(),
        "Last Active": this.fullPractice.last_active
      };
    },

    getPracticeCurrency() {
      if (this.fullPractice.currency_settings) {
        for (const currencySetting of this.fullPractice.currency_settings) {
          if (currencySetting.default) return currencySetting.name;
        }
      } else {
        return null;
      }
    },

    async handlePracticeState() {
      if (this.isPracticeActive) {
        try {
          this.activateDeactivateError = null;
          const activationResponse = await activatePractice(
            this.fullPractice.id
          );
          this.fullPractice = activationResponse.data;
          this.isPracticeButtonActive = activationResponse.data;
        } catch (error) {
          console.log(error);
          this.activateDeactivateError = "Something went wrong";
        }
      } else {
        try {
          this.activateDeactivateError = null;
          const deactivationResponse = await deactivatePractice(
            this.fullPractice.id
          );
          this.fullPractice = deactivationResponse.data;
          this.isPracticeButtonActive = deactivationResponse.data.is_active;
          console.log(deactivationResponse.data.config)
          this.isCrmButtonActive = deactivationResponse.data.config?.isCrmActive;
        } catch (error) {
          console.log(error);
          this.activateDeactivateError = "Something went wrong";
        }
      }
      this.activatePopup = !this.activatePopup;
    },

    resetPracticeState() {
      this.isPracticeActive = !this.isPracticeActive;
    },

    subscriptionChanged(subscription) {
      this.subscription = subscription;
      this.fullPractice.subscription = subscription;
    },

    async handleCrmState() {
      if (this.isCrmActive) {
        try {
          this.activateDeactivateError = null;
          const activationResponse = await activateCrm(this.fullPractice.id);
          this.fullPractice = activationResponse.data;
          this.isCrmButtonActive = activationResponse.data.config?.isCrmActive
        } catch (error) {
          console.log(error);
          this.activateDeactivateError = "Something went wrong";
        }
      } else {
        try {
          this.activateDeactivateError = null;
          const deactivationResponse = await deactivateCrm(this.fullPractice.id);
          this.fullPractice = deactivationResponse.data;
          this.isCrmButtonActive = deactivationResponse.data.config?.isCrmActive
        } catch (error) {
          console.log(error);
          this.activateDeactivateError = "Something went wrong";
        }
      }
      this.activateCrmPopup = !this.activateCrmPopup;
    },

  },
};
</script>

<style scoped>
.practice-title-wrapper .v-input {
  min-width: 150px;
  flex: 0 !important;
}

.clinic-toggle {
  min-width: 100px;
}

.practice-switch {
  width: fit-content;
}

.action-buttons {
  min-width: 25%;
  gap: 30px;
}

.error-message {
  color: var(--danger-color);
}
</style>
