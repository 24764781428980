<template>
    <form class="d-flex flex-column align-items-center" action="">
        <ControlledInputField inputFor="title" :errors="errors.title">
            <input type="text" name="title" placeholder="Title" v-model="formData.title" />
        </ControlledInputField>

        <ControlledInputField inputFor="description" :errors="errors.description" :isTextArea="true">
            <textarea name="description" placeholder="Description" v-model="formData.description" rows="4" />
        </ControlledInputField>

        <ControlledInputField inputFor="link" :errors="errors.link">
            <input type="text" name="link" placeholder="Link" v-model="formData.link" />
        </ControlledInputField>

        <ControlledInputField inputFor="goto" :errors="errors.goto">
            <input type="text" name="goto" placeholder="Go To" v-model="formData.goto" />
        </ControlledInputField>

        <ControlledInputField inputName="date" inputFor="date" :errors="errors.date">
            <input type="date" name="date" v-model="formData.date" />
        </ControlledInputField>

        <CallToActionButton class="mt-4" @buttonClick="handleSubmit">
            Add Announcement
        </CallToActionButton>
    </form>
</template>

<script>
import { addAnnouncement } from "@/api/announcements/announcementsApi.js";

export default {
    name: "AddAnnouncementForm",
    data() {
        const date = this.formatDate(new Date());

        return {
            formData: {
                title: "",
                description: "",
                link: "",
                goto: "",
                date: date
            },
            errors: {},
        };
    },

    methods: {
        async handleSubmit(event) {
            event.preventDefault();
            try {
                await addAnnouncement(
                    {
                        title: this.formData.title,
                        description: this.formData.description,
                        link: this.formData.link,
                        goto: this.formData.goto,
                        date: this.formData.date
                    }
                );
                this.$router.push('/announcements');
            } catch (error) {
                this.errors = error.response.data.errors;
                console.error(error);
            }
        },
        formatDate(date) {
            const d = new Date(date);
            let month = '' + (d.getMonth() + 1);
            let day = '' + d.getDate();
            const year = d.getFullYear();

            if (month.length < 2) month = '0' + month;
            if (day.length < 2) day = '0' + day;

            return [year, month, day].join('-');
        },
    },
};
</script>