import { Api } from "@/api/api.js";

export const getAllPractices = () => {
  return Api.get("/practices").then((response) => {
    return response.data;
  });
};

export const getPracticeById = async (practiceId) => {
  return Api.get(`practices/${practiceId}`).then((response) => {
    return response.data;
  });
};

export const createAccount = (practiceInfo) => {
  return Api.post("/account", practiceInfo).then((response) => {
    return response.data;
  });
};

export const addPracticeToDoctor = (doctorId, payload) => {
  return Api.post(`/doctor/${doctorId}/practice`, payload).then((response) => {
    return response.data;
  });
};

export const deactivatePractice = (practiceId) => {
  return Api.post(`/practices/${practiceId}/deactivate`).then((response) => {
    return response.data;
  });
};

export const activatePractice = (practiceId) => {
  return Api.post(`/practices/${practiceId}/activate`).then((response) => {
    return response.data;
  });
};

export const deactivateCrm = (practiceId) => {
  return Api.post(`/practices/${practiceId}/deactivate-crm`).then((response) => {
    return response.data;
  });
};

export const activateCrm = (practiceId) => {
  return Api.post(`/practices/${practiceId}/activate-crm`).then((response) => {
    return response.data;
  });
};

export const importPatients = (practiceId, patients) => {
  return Api.post(`/practices/${practiceId}/patients-bulk`, patients);
};

export const getAvailableLangauges = () => {
  return Api.get('/languages');
}
