<template>
    <FormLayout>
        <template #title>Announcement #{{ announcement?.id }}</template>
        <template #form v-if="announcement">
            <EditAnnouncementForm :announcement="announcement" />
        </template>
    </FormLayout>
</template>

<script>
import { getAnnouncementById } from "@/api/announcements/announcementsApi.js";
import EditAnnouncementForm from "@/components/EditAnnouncementForm.vue";

export default {
    name: "SingleAnnouncement",
    components: {
        EditAnnouncementForm
    },
    data() {
        return {
            announcementFull: null,
            announcement: null
        };
    },

    async created() {
        this.announcementFull = await getAnnouncementById(this.$route.params.id);
        this.announcement = this.setAnnouncementInfo();
    },

    methods: {
        setAnnouncementInfo() {
            return {
                id: this.announcementFull.data.id,
                title: this.announcementFull.data.title,
                description: this.announcementFull.data.description,
                link: this.announcementFull.data.link,
                goto: this.announcementFull.data.goto,
                date: this.announcementFull.data.date
            };
        },
    },
};
</script>