import {Api} from "@/api/api.js";

export const getAllDoctors = () => {
    return Api.get("/doctors").then((response) => {
        return response.data;
    });
};

export const getDoctorById = (doctorId) =>
    Api.get(`/doctors/${doctorId}`).then(({data}) => data);

export const setDoctorEmail = (doctorId, email) =>
    Api.post(`/doctors/${doctorId}/email`, {email}).then(({data}) => data);

export const resetDoctorPassword = (doctorId) =>
    Api.post(`/doctors/${doctorId}/reset-password`).then(({data}) => data);
