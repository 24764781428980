<template>
  <ViewLayout v-if="doctorFull">
    <template #item-display>
      <h2 class="text-left mb-5">Doctor: {{ doctor.name }}</h2>
      <ItemInfo :item="doctor"/>
      <div class="mt-4">
        <ControlledInputField inputFor="doctorEmail" inputName="Email" :errors="errors.email">
          <input
              type="email"
              id="doctorEmail"
              v-model="newEmail"
              placeholder="Enter new email"
              class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
          />
        </ControlledInputField>
        <CallToActionButton :disabled="isUpdatingEmail" @buttonClick="updateDoctorEmail" class="mt-2">
          <span v-if="isUpdatingEmail">Saving...</span>
          <span v-else>Save</span>
        </CallToActionButton>
      </div>
      <div class="mt-4">
        <CallToActionButton :disabled="isResetting" @buttonClick="resetPassword">
          <span v-if="isResetting">Resetting...</span>
          <span v-else>Reset Password</span>
        </CallToActionButton>
      </div>
    </template>
    <template #lists>
      <ListLayout>
        <template #header>
          <h2>Practices</h2>
          <router-link :to="`/doctor/${doctorFull.id}/practice`">
            <CallToActionButton> Add Practice</CallToActionButton>
          </router-link>
        </template>
        <template #list>
          <DataTable
              v-if="practices.info.length > 0"
              :titles="practices.keys"
              :items="practices.info"
          />
          <TableEmptyState message="This Doctor doesn't have any practice!" v-else/>
        </template>
      </ListLayout>
    </template>
  </ViewLayout>
</template>

<script>
import {getDoctorById, resetDoctorPassword, setDoctorEmail} from "@/api/doctors/index.js";
import ControlledInputField from "@/components/ControlledInputField.vue";
import CallToActionButton from "@/components/CallToActionButton.vue";
import ItemInfo from "@/components/ItemInfo.vue";
import ViewLayout from "@/layouts/ViewLayout.vue";
import ListLayout from "@/layouts/ListLayout.vue";
import DataTable from "@/components/DataTable.vue";
import TableEmptyState from "@/components/TableEmptyState.vue";

export default {
  name: "SingleDoctor",
  components: {
    TableEmptyState,
    DataTable,
    ListLayout,
    ViewLayout,
    ItemInfo,
    CallToActionButton,
    ControlledInputField,
  },
  data() {
    return {
      doctorFull: null,
      doctor: null,
      newEmail: "",
      isUpdatingEmail: false,
      isResetting: false,
      errors: {
        email: null,
      },
      practices: {
        keys: [
          {id: "Id"},
          {name: "Name"},
          {email: "Email"},
          {phone_number: "Phone Number"},
          {country: "Country"},
          {total_sms: "Total SMS"},
        ],
        info: [],
      },
    };
  },
  async created() {
    this.doctorFull = await getDoctorById(this.$route.params.id);
    this.doctor = this.setDoctorInfo();
    this.newEmail = this.doctor.email;
    this.practices.info = this.doctorFull.clinics;
  },
  methods: {
    setDoctorInfo() {
      return {
        id: this.doctorFull.id,
        name: `${this.doctorFull.first_name} ${this.doctorFull.last_name}`,
        email: this.doctorFull.email,
        phone_number: this.doctorFull.phone_number,
        main_clinic: this.doctorFull.clinic?.name,
        preferred_language: this.doctorFull.preferred_language,
        last_active: this.doctorFull.last_active,
      };
    },
    async updateDoctorEmail() {
      this.isUpdatingEmail = true;
      try {
        await setDoctorEmail(this.doctorFull.id, this.newEmail);
        this.doctor.email = this.newEmail;
        this.errors.email = null;
      } catch (error) {
        console.error("Failed to update doctor email", error);
        this.errors.email = error.response?.data?.errors?.email || "Error updating email";
      }
      this.isUpdatingEmail = false;
    },
    async resetPassword() {
      this.isResetting = true;
      try {
        await resetDoctorPassword(this.doctorFull.id);
      } catch (error) {
        console.error("Failed to reset doctor's password", error);
      }
      this.isResetting = false;
    },
  },
};
</script>