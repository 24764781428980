<template>
  <v-card class="text-left">
    <v-layout>
      <v-navigation-drawer
        permanent
        position="left"
        class="navbar-bg d-flex flex-column justify-content-between"
      >
        <template v-slot:prepend>
          <v-list-item :title="getUserInfo.name"></v-list-item>
          <div class="w-75"></div>
          <v-divider></v-divider>
        </template>

        <v-list density="compact" nav>
          <v-list-item
            class="navbar-item"
            v-for="(item, index) in items"
            :key="index"
            :to="item.view"
          >
            <v-icon>{{ item.icon }}</v-icon>
            <v-list-item-title
              class="nav-item"
              v-text="item.title"
            ></v-list-item-title>
          </v-list-item>
        </v-list>
        <CallToActionButton :disabled="isLoading" @buttonClick="handleLogout">Logout</CallToActionButton>
      </v-navigation-drawer>
    </v-layout>
  </v-card>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "VerticalNavbar",

  data() {
    return {
      drawer: true,

      items: [
        { title: "Plans", view: "/plans", icon: "far fa-newspaper" },
        { title: "Practices", view: "/practices", icon: "far fa-building" },
        { title: "Doctors", view: "/doctors", icon: "fas fa-user-plus" },
        { title: "Admins List", view: "/admins", icon: "far fa-id-card" },
        { title: "Reports", view: "/reports/all", icon: "far fa-file-alt" },
        { title: "Announcements", view: "/announcements", icon: "fas fa-bullhorn" },
      ],
      rail: true,
    };
  },

  methods: {
    ...mapActions("user", {
      logout: "logout",
    }),

    async handleLogout() {
      try {
        await this.logout();
        this.$router.push("/login");
      } catch (e) {
        console.log(e);
      }
    },
  },

  computed: {
    ...mapGetters("user", {
      getUserInfo: "getUserInfo",
    }),
    ...mapGetters("loading", {
      isLoading: "getIfIsLoading",
    }),
  },
};
</script>

<style scoped>
.navbar-item {
  text-decoration: none;
  gap: 20px;
}
.navbar-bg {
  padding: 20px;
  background-color: var(--secondary-background-color);
}
.v-navigation-drawer--is-hovering .v-list-item:hover {
  color: #9bd3ff;
}
.nav-item {
  font-size: 14px !important;
}
</style>
