<template>
    <form class="d-flex flex-column align-items-center text-left-form" action="">
        <label>Title</label>
        <ControlledInputField inputFor="formData.title" :errors="errors.title">
            <input type="text" name="title" placeholder="Title" v-model="formData.title" />
        </ControlledInputField>

        <label>Description</label>
        <ControlledInputField inputFor="description" :errors="errors.description" :isTextArea="true">
            <textarea name="description" placeholder="Description" v-model="formData.description" rows="4" />
        </ControlledInputField>

        <label>Link</label>
        <ControlledInputField inputFor="formData.link" :errors="errors.link">
            <input type="text" name="link" placeholder="Link" v-model="formData.link" />
        </ControlledInputField>

        <label>Go To</label>
        <ControlledInputField inputFor="formData.goto" :errors="errors.goto">
            <input type="text" name="goto" placeholder="Go To" v-model="formData.goto" />
        </ControlledInputField>

        <label>Date</label>
        <ControlledInputField inputName="date" inputFor="date" :errors="errors.date">
            <input type="date" name="date" v-model="formData.date" />
        </ControlledInputField>

        <CallToActionButton class="mt-4" @buttonClick="handleSubmit">
            Save
        </CallToActionButton>
    </form>
</template>

<script>
import { editAnnouncement } from "@/api/announcements/announcementsApi.js";

export default {
    name: "EditAnnouncementForm",
    props: {
        announcement: {
            required: true,
        },
    },
    data() {
        return {
            formData: { ...this.announcement },
            errors: {}
        };
    },

    methods: {
        async handleSubmit(event) {
            event.preventDefault();
            try {
                await editAnnouncement(this.formData.id, this.formData);
                this.$router.push('/announcements');
            } catch (error) {
                this.errors = error.response.data.errors;
                console.error(error);
            }
        }
    },
};
</script>

<style scoped>
.text-left-form {
    width: 100%;
}

.text-left-form label {
    width: 100%;
    text-align: left;
}
</style>