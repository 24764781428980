<template>
    <FormLayout>
        <template #title>Add Announcement</template>
        <template #form>
            <AddAnnouncementForm />
        </template>
    </FormLayout>
</template>

<script>
import AddAnnouncementForm from "@/components/AddAnnouncementForm.vue";

export default {
    name: "AddAnnouncement",
    components: {
        AddAnnouncementForm,
    },
};
</script>