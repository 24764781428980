import { Api } from "../api";

export const getAllAnnouncements = () => {
    return Api.get("/announcements").then((response) => {
        return response.data;
    });
};

export const addAnnouncement = (announcement) => {
    return Api.post("/announcements", announcement);
};

export const getAnnouncementById = (announcementId) => {
    return Api.get(`/announcements/${announcementId}`);
};

export const editAnnouncement = (announcementId, announcement) => {
    return Api.put(`/announcements/${announcementId}`, announcement);
};

export const deleteAnnouncement = (announcementId) => {
    return Api.delete(`/announcements/${announcementId}`);
};
